import React from 'react';
import '../styles/components/keyButton.css';
import ArrowRight from '../images/icons/arrow_right.svg';

const Keybutton = ({ keyText, label, refID, onMouseEnter, onMouseLeave }) => {
    return (
        <button ref={refID} className='key-button' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {!keyText && (
                <span className='key-text-icon'>
                    <img 
                    src={ArrowRight} 
                    alt="→" 
                    draggable="false"/>
                </span>
            )}
            {keyText && (
                <span>{keyText}</span>
            )}
            {label}
        </button>
    );
}

export default Keybutton;
