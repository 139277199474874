import React, { useState, useEffect, useRef } from 'react';
import gsap, { ScrollTrigger, ScrollToPlugin } from 'gsap/all';
import { motion } from 'framer-motion';
import Styleswrapper from '../components/StylesWrapper';
import PageMeta from '../components/blocks/helmet/PageMeta';
import Mainheader from '../components/MainHeader';
import Keybutton from '../components/KeyButton';
import emailjs from '@emailjs/browser';
import Footer from '../components/Footer';
import '../styles/contact.css';

// icons
import WineIcon from '../images/icons/wine.png';
import DoorIcon from '../images/contact/door.png';
import EyesIcon from '../images/icons/eyes.png';
import LandlineIcon from '../images/icons/landline.png';
import ShieldIcon from '../images/icons/shield.png';

// sounds
import KNOCK_SOUND from '../sounds/contact/knock.wav';


const Contact = () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);


    const [knocks, setKnocks] = useState(0);
    const knockSoundPlayer = useRef();

    const knockOnTheDoor = () => {
        knockSoundPlayer.current.currentTime = 0;
        knockSoundPlayer.current.play();
        setKnocks(knocks + 1);
    };

    const knockCount = useRef();
    const knockCountTimeline = useRef();
    const contactDetailsRef = useRef();
    const contactFormRef = useRef();

    // setup knock count animation
    useEffect(() => {
        knockCountTimeline.current = gsap.timeline({
            paused: true
        })
            .fromTo(knockCount.current, {
                y: 100,
                opacity: 0,
                scale: 0.85,
            }, {
                y: 0,
                opacity: 1,
                scale: 1,
                duration: 0.4,
                ease: "elastic.out(1, 1.5)",
            });
    }, []);


    useEffect(() => {
        if (knocks === 0) return;

        // animate knock count when door is knocked
        knockCountTimeline.current.restart();

        if (knocks === 2) {
            gsap.to(".contact-intro", {
                opacity: 0.4,
                scrollTrigger: {
                    trigger: ".contact-form-wrapper",
                    start: "top bottom",
                    end: "top 50%",
                    scrub: 1,
                }
            });

            contactFormRef.current.elements.message.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });

            setTimeout(() => {
                contactFormRef.current.elements.message.focus();
            }, 500);

        }

    }, [knocks]);


    // contact form elements
    const contactFormName = useRef();
    const contactFormEmail = useRef();
    const contactFormMessage = useRef();
    const contactFormSubmitBtn = useRef();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleContactFormSubmit = (e) => {
        e.preventDefault();

        if (formSubmitted) return;

        let name = contactFormName.current.value.trim();
        let email = contactFormEmail.current.value.trim();
        let message = contactFormMessage.current.value.trim();
        let url;

        if (name === "" || email === "") return;

        if (window.location.href.includes("?r=")) {
            url = window.location.href.split("?r=")[1];
        }
        else {
            url = document.referrer;
        }

        if (url === "") {
            url = "contact";
        }

        let contactFormData = { name, email, message, url };

        submitContactForm(contactFormData);
        contactFormSubmitBtn.current.innerText = "Sending...";
    };

    const submitContactForm = (data) => {

        const serviceID = "service_i31mmlg";
        const templateWithMessage = "template_yhv24d9";
        const templateWithoutMessage = "template_mbus8xy";
        const templateID = data.message.length ? templateWithMessage : templateWithoutMessage;
        const userID = "6EYXpRRlRsj0pe00B";

        emailjs.send(serviceID, templateID, data, userID)
            .then((result) => {
                contactFormSubmitBtn.current.innerText = "Sent ✔";
                setFormSubmitted(true);
            }, (error) => {
                console.log(error.text);
                alert("System glitched. Please try again.");
                contactFormSubmitBtn.current.innerText = "Submit securely";
            });
    };


    const knockCopyVariants = {
        initial: {
            opacity: 0,
            y: "5%",
        },
        animate: {
            opacity: 1,
            y: "0%",
            transition: {
                duration: 0.4,
                ease: "circOut",
            }
        },
    };

    const wineIconVariants = {
        initial: {
            opacity: 0,
            y: "25%",
        },
        animate: {
            opacity: 1,
            y: "0%",
            transition: {
                duration: 0.4,
                ease: "circOut",
            }
        },
    };

    const knockDoorIconVariants = {
        initial: {
            opacity: 0,
            scale: 0.9,
            x: "25%",
        },
        animate: {
            opacity: 1,
            scale: 1,
            x: "0%",
            transition: {
                duration: 0.4,
                ease: "backOut",
                delay: 1.2,
            }
        },
    }

    const knockDoorCopyVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                duration: 0.4,
                ease: "circOut",
                delay: 1.2,
            }
        },
    }


    return (
        <Styleswrapper
            bgColor1="#081D4D"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="20%"
            noise="true"
        >
            <PageMeta
                theme="#081D4D"
                title="Contact — Nihar Bhagat"
                description="Please feel free to knock"
                image="../images/social/contact/default.jpg"
                twitterImage="../images/social/contact/twitter.jpg"
            />

            <Mainheader
                classes="contact-header"
                bgColor1="#081D4D"
                bgColor2="#00070D"
                bg1Stop="0%"
                bg2Stop="20%"
                noise="true"
            />


            <section className="contact-main">
                <div className="contact-main-wrapper">
                    <div className="contact-knock-wrapper">

                        <audio src={KNOCK_SOUND} ref={knockSoundPlayer}></audio>

                        <div className="contact-knock">
                            <div className="knock-count" ref={knockCount}>{(
                                knocks > 0 ? knocks : ''
                            )}</div>
                            <motion.div
                                className="door-img"
                                onMouseDown={knockOnTheDoor}
                                tabIndex="-1"
                                role="button"
                                variants={knockDoorIconVariants}
                                initial="initial"
                                animate="animate"
                            >
                                <img src={DoorIcon}
                                    alt="🚪"
                                    draggable="false"
                                    loading='eager'
                                />
                            </motion.div>
                        </div>
                    </div>
                    <div className="contact-intro">
                        <motion.div
                            variants={wineIconVariants}
                            initial="initial"
                            animate="animate"
                            className="intro-icon">
                            <img src={WineIcon}
                                alt="🥂"
                                draggable="false"
                                loading='eager'
                            />
                        </motion.div>
                        <motion.p
                            variants={knockCopyVariants}
                            initial="initial"
                            animate="animate"
                        >
                            I am looking to give my creative practice its deepest and farthest expression. For that I am constantly learning, creating, collaborating on projects in industries I&nbsp;don't know of. <br /><br />Planning is the base color of my Practice, improv is the accent, and this portfolio is old.
                        </motion.p>
                    </div>

                    <div className={`contact-info-group ${knocks > 0 ? 'info-visible' : ''} ${knocks > 1 ? 'form-visible' : ''}`}>
                        <motion.p
                            className='contact-knock-invite'
                            variants={knockDoorCopyVariants}
                            initial="initial"
                            animate="animate"
                        >
                            Please feel free to knock for no reason
                        </motion.p>

                        <div className="contact-details" id='contactDetails' ref={contactDetailsRef}>
                            <a href="mailto:focus@niharbhagat.com"
                                className="email"
                            >
                                <img src={EyesIcon}
                                    alt="👀"
                                    draggable="false"
                                    loading='eager'
                                />
                                <span>focus@niharbhagat.com</span>
                            </a>
                            <a href="tel:919825038818"
                                className="phone"
                            >
                                <img src={LandlineIcon}
                                    alt="☎️"
                                    draggable="false"
                                    loading='eager'
                                />
                                <span>+91 982-503-8818</span>
                            </a>
                        </div>

                        <div className="contact-form-wrapper" id="contactForm">
                            <form className="contact-form"
                                ref={contactFormRef}
                                onSubmit={handleContactFormSubmit}
                            >

                                <div className="field-group">
                                    <input type="text"
                                        name='message'
                                        placeholder="Drop your line here"
                                        ref={contactFormMessage}
                                    />
                                </div>
                                <div className="field-group">
                                    <input type="text"
                                        name='name'
                                        placeholder="Your real name"
                                        autoCapitalize='on'
                                        autoComplete='name'
                                        required="required"
                                        ref={contactFormName}
                                    />
                                    <input type="email"
                                        name='email'
                                        placeholder="Your real email"
                                        autoComplete='email'
                                        inputMode="email"
                                        required="required"
                                        ref={contactFormEmail}
                                    />
                                </div>
                                <div className="field-group">
                                    <Keybutton refID={contactFormSubmitBtn}
                                        label="Submit securely"
                                        keyText="ENT"
                                    />
                                    <div className="contact-form-submit-info">
                                        <img src={ShieldIcon}
                                            alt="🛡️"
                                            draggable="false"
                                            loading='eager'
                                        />
                                        <span>
                                            Data protected using<br />common sense code
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </section>

            {knocks > 1 && (
                <Footer
                    classes="contact-footer"
                    furtherReadingLinks={true}
                />
            )}
        </Styleswrapper>
    );
}

export default Contact;
